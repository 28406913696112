import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const ProductDetails = () => {
  const [subCategories, setSubCategories] = useState([]);
  const { productId } = useParams();
  const [product, setProduct] = useState({
    name: "",
    summary_description: "",
    category_id: "", // Initialize category_id
    price: 0,
    inventory: 0,
    is_available: false,
    details: [],
    product_images: [],
  });
  const base_url = process.env.REACT_APP_BASE_URL;

  const refreshPage = () => {
    window.location.reload();
  };

  const fetchProduct = async () => {
    try {
      const response = await axios.get(`${base_url}/products/${productId}`);
      const productData = response.data;
      setProduct({
        ...productData,
        category_id: productData.category_id || "", // Ensure category_id is set
      });
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };
  console.log("product: ", product);

  useEffect(() => {
    fetchProduct();
  }, [productId]);

  const handleDetailChange = (index, key, value) => {
    const updatedDetails = [...product.details];
    updatedDetails[index][key] = value;
    setProduct({ ...product, details: updatedDetails });
  };

  const handleAddDetail = () => {
    const updatedDetails = [
      ...product.details,
      { title: "New Title", description: "New Description" },
    ];
    setProduct({ ...product, details: updatedDetails });
  };

  const handleDeleteDetail = (index) => {
    const updatedDetails = product.details.filter((_, i) => i !== index);
    setProduct({ ...product, details: updatedDetails });
  };

  const handleImageChange = (index, value) => {
    const updatedImages = [...product.product_images];
    updatedImages[index].image = value;
    setProduct({ ...product, product_images: updatedImages });
  };

  const handleAddImage = () => {
    const updatedImages = [
      ...product.product_images,
      { image: "http://127.0.0.1:5000/static/images/new-image.jpg" },
    ];
    setProduct({ ...product, product_images: updatedImages });
  };

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUploadClick = async () => {
    if (!selectedFiles) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    Array.from(selectedFiles).forEach((file) => {
      formData.append("images[]", file);
    });

    try {
      const response = await axios.post(
        `${base_url}/products/images/${product.public_id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setUploadStatus("Upload successful!");
      console.log("Uploaded images:", response.data);
      fetchProduct();
    } catch (error) {
      setUploadStatus("Upload failed.");
      console.error(error.response ? error.response.data : error.message);
    }
  };

  const deleteImage = async (publicId) => {
    const url = base_url + `/products/images/${publicId}`;
    try {
      const response = await axios.delete(url);
      console.log("Image deleted successfully:", response.data);
      fetchProduct();
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Error deleting image:", error.response.data);
      } else {
        console.error("Network or server error:", error.message);
      }
      throw error;
    }
  };

  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const subcategoriesResponse = await axios.get(
          base_url + "/subcategories/"
        );
        const fetchedSubCategories = subcategoriesResponse.data.map(
          (subCategory) => ({
            ...subCategory,
            displayName: `${subCategory.name} - ${subCategory.category.name}`,
          })
        );
        setSubCategories(fetchedSubCategories);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };
    fetchSubCategories();
  }, []);

  const handleUpdateProduct = () => {
    const updatedProduct = {
      name: product.name,
      summary_description: product.summary_description,
      category_id: product.category_id,
      price: product.price,
      inventory: product.inventory,
      is_available: product.is_available,
      details: product.details,
    };
    console.log("updatedProduct: ", updatedProduct);

    axios
      .put(base_url + "/products/" + productId, updatedProduct)
      .then((response) => {
        console.log("Product updated successfully:", response.data);
        alert(`Product ${product.name} updated successfully!`);
        refreshPage();
      })
      .catch((error) => {
        console.error("Error updating product:", error);
        alert("There was an error updating the product.");
      });
  };

  if (!product || !product.category_id) return <div>Loading...</div>;

  return (
    <div className="edit_form_container">
      <h1>Product Details</h1>
      <form>
        <label htmlFor="name">Product Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={product.name}
          onChange={(e) => setProduct({ ...product, name: e.target.value })}
        />

        <label htmlFor="summary_description">Summary Description</label>
        <textarea
          id="summary_description"
          name="summary_description"
          rows="3"
          value={product.summary_description}
          onChange={(e) =>
            setProduct({ ...product, summary_description: e.target.value })
          }
        />

        <label htmlFor="price">Price</label>
        <input
          type="number"
          id="price"
          name="price"
          value={product.price}
          onChange={(e) =>
            setProduct({ ...product, price: parseFloat(e.target.value) })
          }
        />

        <label htmlFor="inventory">Inventory</label>
        <input
          type="number"
          id="inventory"
          name="inventory"
          value={product.inventory}
          onChange={(e) =>
            setProduct({ ...product, inventory: parseInt(e.target.value) })
          }
        />

        <div className="input-box">
          <label htmlFor="category">Category</label>
          <select
            name="category_id"
            id="category_id"
            value={product.category_id || ""}
            onChange={(e) =>
              setProduct((prevProduct) => ({
                ...prevProduct,
                category_id: e.target.value,
              }))
            }
          >
            <option value="" disabled>
              Select a category
            </option>
            {subCategories.map((subCategory) => (
              <option key={subCategory.id} value={subCategory.category.id}>
                {subCategory.displayName}
              </option>
            ))}
          </select>
        </div>

        <label htmlFor="is_available">Availability</label>
        <select
          id="is_available"
          name="is_available"
          value={product.is_available ? "true" : "false"}
          onChange={(e) =>
            setProduct({ ...product, is_available: e.target.value === "true" })
          }
        >
          <option value="true">Available</option>
          <option value="false">Not Available</option>
        </select>

        <label htmlFor="details">Details</label>

        <div className="edit_product_details">
          {product.details.map((detail, index) => (
            <div key={index} className="detail-item">
              <label>Title:</label>
              <input
                type="text"
                value={detail.title}
                onChange={(e) =>
                  handleDetailChange(index, "title", e.target.value)
                }
              />

              <label>Description:</label>
              <input
                type="text"
                value={detail.description}
                onChange={(e) =>
                  handleDetailChange(index, "description", e.target.value)
                }
              />

              <button type="button" onClick={() => handleDeleteDetail(index)}>
                Delete
              </button>
            </div>
          ))}
        </div>
        <table className="details-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {product.details.map((detail, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    value={detail.title}
                    onChange={(e) =>
                      handleDetailChange(index, "title", e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={detail.description}
                    onChange={(e) =>
                      handleDetailChange(index, "description", e.target.value)
                    }
                  />
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleDeleteDetail(index)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="button" onClick={handleAddDetail}>
          Add New Detail
        </button>
        <br />

        <label htmlFor="images">Images</label>
        <br />
        <div className="product-image">
          {product.product_images.map((img, index) => (
            <div key={index}>
              <img src={img.image} alt="Product" />
              <div className="image-controls">
                <input
                  type="text"
                  value={img.image}
                  onChange={(e) => handleImageChange(index, e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => deleteImage(img.public_id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
        <div>
          <label type="button" onClick={handleAddImage}>
            Add New Image
          </label>

          <input type="file" multiple onChange={handleFileChange} />
          <button type="button" onClick={handleUploadClick}>
            Upload
          </button>
          {uploadStatus && <p>{uploadStatus}</p>}
        </div>

        <button type="button" onClick={handleUpdateProduct}>
          Update Product
        </button>
      </form>
    </div>
  );
};

export default ProductDetails;
