import React from "react";

function Footer() {
  // Dynamically require all images from the /images folder
  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  };

  // Load images from directory
  const images = importAll(
    require.context("../../images", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <h3>Download Our App</h3>
            <p>Get the app for Android and iOS.</p>
            <div className="app-logo">
              <img src={images["play-store.png"]} alt="Play Store" />
              <img src={images["app-store.png"]} alt="App Store" />
            </div>
          </div>
          <div className="footer-col">
            <img src={images["cyan.png"]} alt="Bitix Logo" />
            <p>
              Delivering precision components for a digital world. Your vision,
              our silicon.
            </p>
          </div>
          <div className="footer-col">
            <h3>Useful Links</h3>
            <ul>
              <li>Coupons</li>
              <li>Blog Post</li>
              <li>Return Policy</li>
              <li>Join Affiliate</li>
            </ul>
          </div>
          <div className="footer-col">
            <h3>Follow Us</h3>
            <ul className="social-media">
              <li>
                <a href="#">
                  <i className="bx bxl-facebook"></i> Facebook
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bx bxl-twitter"></i> Twitter
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bx bxl-instagram-alt"></i> Instagram
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bx bxl-youtube"></i> YouTube
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <p className="copyright">Copyright 2021 - Bitix</p>
      </div>
    </div>
  );
}

export default Footer;
