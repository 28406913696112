import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Make sure Header is simple and only uses Link after it's inside BrowserRouter
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  // Dynamically require all images from the /images folder
  const importAll = (r) => {
    let images = {};
    r.keys().map((item) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  };

  // Load images from directory
  const images = importAll(
    require.context("../../images", false, /\.(png|jpe?g|svg)$/)
  );

  // const toggleMenu = () => {
  //   setMenuOpen(!menuOpen);
  // };

  const toggleMenu = () => {
    if (isMobile) {
      setMenuOpen(!menuOpen);
    }
  };

  // Update the isMobile state on window resize
  const handleResize = () => {
    setIsMobile(window.innerWidth < 800);
    if (window.innerWidth >= 900) {
      setMenuOpen(false); // Close the menu if resizing above 900px
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="header">
      <div className="container">
        <div className="navbar">
          <div className="logo">
            <img src={images["bitix_logo.png"]} alt="Bitix Logo" width="90px" />
          </div>
          <nav>
            <ul
              id="MenuItems"
              style={{ display: isMobile ? (menuOpen ? "block" : "none") : "" }}
            >
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/account">Account</Link>
              </li>
            </ul>
          </nav>
          <Link to="/cart" className="bx bx-cart"></Link>
          <i className="bx bx-menu menu-icon" onClick={toggleMenu}></i>
        </div>
      </div>
    </div>
  );
};

export default Header;
