import React, { useEffect, useState } from "react";
import axios from "axios";

const SubCategories = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newSubCategory, setNewSubCategory] = useState({
    name: "",
    description: "",
    category_id: "", // ID of the selected category for the sub-category
  });
  const [editSubCategory, setEditSubCategory] = useState({
    name: "",
    description: "",
    category_id: "",
    public_id: "", // ID of the sub-category to edit
  });
  const base_url = process.env.REACT_APP_BASE_URL;

  // Fetch categories and sub-categories from the API
  const fetchCategories = async () => {
    try {
      const response = await axios.get(base_url + "/categories");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSubCategories = async () => {
    try {
      const response = await fetch(`${base_url}/products/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      setSubCategories(await response.json());
      console.log("sub-categories", subCategories);
    } catch (error) {
      console.error("Error feching sub-categories:", error);
    }
  };
  //   try {
  //     const response = await axios.get(base_url + "/subcategories");
  //     setSubCategories(response.data);
  //   } catch (error) {
  //     console.error("Error fetching sub-categories:", error);
  //   }
  // };

  useEffect(() => {
    fetchSubCategories();
    fetchCategories();
  }, []);

  // Handle input change for sub-category fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSubCategory({ ...newSubCategory, [name]: value });
  };

  // Handle input change for edit sub-category fields
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditSubCategory({ ...editSubCategory, [name]: value });
  };

  // Add sub-category
  const addSubCategory = async () => {
    try {
      const response = await axios.post(
        base_url + "/subcategories/",
        newSubCategory
      );

      setShowModal(false); // Close the modal
      setNewSubCategory({ name: "", description: "", category_id: "" }); // Reset fields
      fetchSubCategories(); // Refresh sub-categories after adding
    } catch (error) {
      console.error("Error adding sub-category:", error);
    }
  };

  // Edit sub-category
  const editSubCategoryHandler = async () => {
    try {
      const response = await axios.put(
        base_url + `/subcategories/${editSubCategory.public_id}`,
        editSubCategory
      );

      fetchSubCategories(); // Refresh sub-categories after updating
      setEditSubCategory({
        name: "",
        description: "",
        category_id: "",
        public_id: "",
      }); // Clear the edit form
    } catch (error) {
      console.error("Error updating sub-category:", error);
    }
  };

  // Handle deleting a sub-category
  const deleteSubCategory = async (subCategoryId) => {
    try {
      await axios.delete(base_url + `/subcategories/${subCategoryId}`);
      fetchSubCategories(); // Refresh sub-categories after deletion
    } catch (error) {
      console.error("Error deleting sub-category:", error);
    }
  };

  return (
    <div className="container-fluid position-relative d-flex p-0 subcategories-container">
      <div className="sidebar pe-4 pb-3">{/* Sidebar Navigation */}</div>

      <div className="content">
        <nav className="navbar navbar-expand bg-secondary navbar-dark sticky-top px-4 py-0">
          <a href="#" className="navbar-brand">
            Sub-Categories
          </a>
          <div className="ml-auto subcategories-ribbon">
            <button
              className="btn btn-primary mx-2"
              onClick={() => setShowModal(true)}
            >
              Add Sub-Category
            </button>
            <button className="btn btn-secondary" onClick={fetchSubCategories}>
              Refresh
            </button>
          </div>
        </nav>

        {/* Add Sub-Category Modal */}
        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Add New Sub-Category</h3>
              <select
                name="category_id"
                value={newSubCategory.category_id}
                onChange={handleInputChange}
                className="form-control mb-2"
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.public_id} value={category.public_id}>
                    {category.name}
                  </option>
                ))}
              </select>

              <input
                type="text"
                name="name"
                value={newSubCategory.name}
                placeholder="Sub-Category Name"
                onChange={handleInputChange}
                className="form-control mb-2"
              />
              <input
                type="text"
                name="description"
                value={newSubCategory.description}
                placeholder="Sub-Category Description"
                onChange={handleInputChange}
                className="form-control mb-2"
              />
              <button className="btn btn-success" onClick={addSubCategory}>
                Save
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {/* Sub-Categories List */}
        <table className="table mt-4">
          <thead>
            <tr>
              <th>Sub-Category ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {subCategories.map((subCategory) => (
              <React.Fragment key={subCategory.public_id}>
                <tr>
                  <td>{subCategory.public_id}</td>
                  <td>{subCategory.name}</td>
                  <td>{subCategory.description}</td>
                  <td>{subCategory.category_name}</td>
                  <td>
                    <button
                      className="btn btn-edit"
                      onClick={() => {
                        setEditSubCategory({
                          public_id: subCategory.public_id,
                          name: subCategory.name,
                          description: subCategory.description,
                          category_id: subCategory.category_id,
                        });
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-delete"
                      onClick={() => deleteSubCategory(subCategory.public_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>

                {/* Edit Form Row */}
                {editSubCategory.public_id === subCategory.public_id && (
                  <tr>
                    <td colSpan="5">
                      <div className="edit-form">
                        <select
                          name="category_id"
                          value={editSubCategory.category_id}
                          onChange={handleEditInputChange}
                          className="form-control mb-2"
                        >
                          <option value="">Select Category</option>
                          {categories.map((category) => (
                            <option
                              key={category.public_id}
                              value={category.public_id}
                            >
                              {category.name}
                            </option>
                          ))}
                        </select>

                        <input
                          type="text"
                          name="name"
                          value={editSubCategory.name}
                          placeholder="Sub-Category Name"
                          onChange={handleEditInputChange}
                          className="form-control mb-2"
                        />
                        <input
                          type="text"
                          name="description"
                          value={editSubCategory.description}
                          placeholder="Sub-Category Description"
                          onChange={handleEditInputChange}
                          className="form-control mb-2"
                        />
                        <div className="save-cancel-btn">
                          <button
                            className="btn btn-success"
                            onClick={editSubCategoryHandler}
                          >
                            Save Changes
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() =>
                              setEditSubCategory({
                                name: "",
                                description: "",
                                category_id: "",
                                public_id: "",
                              })
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubCategories;
