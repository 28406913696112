import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./add_product.css";

const AddProductForm = () => {
  const navigate = useNavigate();
  const [subCategories, setSubCategories] = useState([]);
  const [productDetails, setProductDetails] = useState([
    { title: "", description: "" },
  ]);
  const [imageFields, setImageFields] = useState([0]);
  const [isProductAdded, setIsProductAdded] = useState(false);
  const [hasVariant, setHasVariant] = useState(false);
  const [variants, setVariants] = useState([{ name: "", price: "" }]);
  const [productVariantDetails, setProductVariantDetails] = useState([
    { title: "", description: "" },
  ]);
  const base_url = process.env.REACT_APP_BASE_URL;
  console.log(base_url);

  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const subcategoriesResponse = await axios.get(
          base_url + "/subcategories/"
        );
        const fetchedSubCategories = subcategoriesResponse.data.map(
          (subCategory) => ({
            ...subCategory,
            displayName: `${subCategory.name} - ${subCategory.category.name}`,
          })
        );
        setSubCategories(fetchedSubCategories);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };
    fetchSubCategories();
  }, []);

  const handleInputChange = (index, event) => {
    if (productDetails[index]) {
      const updatedDetails = [...productDetails];
      updatedDetails[index][event.target.name] = event.target.value;
      setProductDetails(updatedDetails);
    }
    if (productVariantDetails[index]) {
      const updatedVariantDetails = [...productVariantDetails];
      updatedVariantDetails[index][event.target.name] = event.target.value;
      setProductVariantDetails(updatedVariantDetails);
    }
  };

  const handleVariantChange = (index, event) => {
    const updatedVariants = [...variants];
    updatedVariants[index][event.target.name] = event.target.value;
    setVariants(updatedVariants);
  };

  const addProductDetail = () =>
    setProductDetails([...productDetails, { title: "", description: "" }]);
  const addProductVariantDetail = () =>
    setProductVariantDetails([
      ...productVariantDetails,
      { title: "", description: "" },
    ]);
  const addImageField = () =>
    setImageFields([...imageFields, imageFields.length]);
  const addVariant = () => setVariants([...variants, { name: "", price: "" }]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    imageFields.forEach((_, index) => {
      const fileInput = event.target[`product_image_${index}`];
      if (fileInput && fileInput.files[0]) {
        formData.append(`product_images[${index}]`, fileInput.files[0]);
      }
    });

    const productData = {
      name: formData.get("product_name"),
      summary_description: formData.get("product_summary"),
      category_id: formData.get("product_sub_category_id"),
      price: formData.get("product_price"),
      rating: "2.0", // Add rating as per your request
      is_available: "True",
      has_variant: hasVariant ? "True" : "False", // Set the correct value for has_variant
      details: hasVariant
        ? [] // No product details if the product has variants
        : productDetails.map((detail) => ({
            title: detail.title,
            description: detail.description,
          })),
      variants: hasVariant
        ? variants.map((variant) => ({
            price: variant.price,
            variant: variant.name,
            variant_details: productVariantDetails.map((variant_detail) => ({
              title: variant_detail.title,
              description: variant_detail.description,
            })),
          }))
        : [], // Empty array for variants if there are no variants
    };

    try {
      const response = await fetch(base_url + "/products/", {
        method: "POST",
        body: JSON.stringify(productData),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        const result = await response.json();
        const publicId = result.public_id;

        const imageUploadForm = new FormData();
        imageFields.forEach((_, index) => {
          const fileInput = event.target[`product_image_${index}`];
          if (fileInput && fileInput.files[0]) {
            imageUploadForm.append(`images[]`, fileInput.files[0]);
          }
        });

        const imageUploadResponse = await fetch(
          base_url + `/products/images/${publicId}`,
          {
            method: "POST",
            body: imageUploadForm,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("Success:", data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        console.log("imageUploadResponse: ", imageUploadResponse);

        const imageUploadData = await imageUploadResponse.json();

        if (imageUploadResponse.ok) {
          console.log("Image uploaded successfully:", imageUploadData);
          setIsProductAdded(true);
          navigate("/products");
          alert("Product and image added successfully!");
        } else {
          console.error("Error uploading image:", imageUploadData);
          alert("Failed to upload product image(s). Please try again.");
        }
      } else {
        alert("Failed to add product. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <div className="form_container">
      <div className="add_product_container">
        <div className="add_product_title">Add Product</div>
        <form id="product_form" onSubmit={handleFormSubmit}>
          <div className="product_detail">
            <div className="input-box">
              <span className="details">Product Name</span>
              <input
                type="text"
                name="product_name"
                placeholder="Product Name"
                required
              />
            </div>
            <div className="input-box sub-category-div">
              <span className="details">Subcategory</span>
              <select name="product_sub_category_id" required>
                <option value="">Select Subcategory</option>
                {subCategories.map((subCategory) => (
                  <option
                    key={subCategory.category.public_id}
                    value={subCategory.category.public_id}
                  >
                    {subCategory.displayName}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-box">
              <span className="details">Product Price</span>
              <input
                type="number"
                name="product_price"
                placeholder="Product Price"
                step="0.01"
                required
              />
            </div>
            <div className="input-box">
              <span className="details">Product Summary</span>
              <input
                type="text"
                name="product_summary"
                placeholder="Product Summary"
                required
              />
            </div>
          </div>

          {/* Image Fields */}
          <div>
            <span className="details">Product Images</span>
            {imageFields.map((_, index) => (
              <div key={index} className="input-box">
                <input
                  type="file"
                  name={`product_image_${index}`}
                  id={`product_image_${index}`}
                  accept="image/*"
                  required
                />
              </div>
            ))}
            <button type="button" onClick={addImageField}>
              Add Image Field
            </button>
          </div>

          {/* Has Variant Toggle */}
          <div
            className="input-box"
            style={{
              marginBottom: "10px",
              display: "inline-flex",
              borderBottom: "1px solid grey",
            }}
          >
            <label
              style={{
                width: "300px",
                alignContent: "center",
                marginLeft: "10px",
              }}
            >
              Has Variant
            </label>
            <input
              type="checkbox"
              name="product_has_variant"
              checked={hasVariant}
              onChange={(e) => setHasVariant(e.target.checked)}
            />
          </div>

          {/* Product Details Section */}
          {!hasVariant &&
            productDetails.map((detail, index) => (
              <div className="product_details" key={index}>
                <div className="details_title">Product Details {index + 1}</div>
                <div className="details_inputs">
                  <div className="input-box">
                    <span className="details">Detail Title</span>
                    <input
                      type="text"
                      name="title"
                      value={detail.title}
                      placeholder="Detail Title"
                      onChange={(event) => handleInputChange(index, event)}
                      required
                    />
                  </div>
                  <div className="input-box">
                    <span className="details">Detail Description</span>
                    <input
                      type="text"
                      name="description"
                      value={detail.description}
                      placeholder="Detail Description"
                      onChange={(event) => handleInputChange(index, event)}
                      required
                    />
                  </div>
                </div>
              </div>
            ))}

          {/* Variant Input Section */}
          {hasVariant &&
            variants.map((variant, variantIndex) => (
              <div className="variant_input" key={variantIndex}>
                <div className="variant_title">Variant {variantIndex + 1}</div>
                <div className="product_variant_detail">
                  <div className="input-box">
                    <span className="details">Variant Name</span>
                    <input
                      type="text"
                      name="name"
                      value={variant.name}
                      placeholder="Variant Name"
                      onChange={(event) =>
                        handleVariantChange(variantIndex, event)
                      }
                      required
                    />
                  </div>
                  <div className="input-box">
                    <span className="details">Variant Price</span>
                    <input
                      type="number"
                      name="price"
                      value={variant.price}
                      placeholder="Variant Price"
                      step="0.01"
                      onChange={(event) =>
                        handleVariantChange(variantIndex, event)
                      }
                      required
                    />
                  </div>
                  {productVariantDetails.map((variant_detail, detailIndex) => (
                    <div className="product_variant_details" key={detailIndex}>
                      <div className="variant_details_title ">
                        Product Variant Details {detailIndex + 1}
                      </div>
                      <div className="variant_details_inputs">
                        <div className="input-box">
                          <span className="details">Detail Title</span>
                          <input
                            type="text"
                            name="title"
                            value={variant_detail.title}
                            placeholder="Detail Title"
                            onChange={(event) =>
                              handleInputChange(detailIndex, event)
                            }
                            required
                          />
                        </div>
                        <div className="input-box">
                          <span className="details">Detail Description</span>
                          <input
                            type="text"
                            name="description"
                            value={variant_detail.description}
                            placeholder="Detail Description"
                            onChange={(event) =>
                              handleInputChange(detailIndex, event)
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="button">
                  {hasVariant && (
                    <button
                      type="button"
                      className="btn_more_description"
                      onClick={addProductVariantDetail}
                    >
                      Add Product Variant Details
                    </button>
                  )}
                </div>
              </div>
            ))}

          <div className="button">
            {!hasVariant && (
              <button
                type="button"
                className="btn_more_description"
                onClick={addProductDetail}
              >
                Add Product Details
              </button>
            )}
            {hasVariant && (
              <button
                type="button"
                className="btn_add_variant"
                onClick={addVariant}
              >
                Add Variant
              </button>
            )}
            <input type="submit" value="Add Product" />
          </div>
        </form>

        {isProductAdded && (
          <div className="view_products_link">
            <Link to="/products">View Products</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddProductForm;
