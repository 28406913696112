import React, { useEffect, useState } from "react";
import axios from "axios";
import ProductFetcher from "../components/product-fetcher/product_fetcher";
import { Link } from "react-router-dom";

const Products = () => {
  const [products, setProducts] = useState([]);
  const base_url = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);

  const refreshPage = () => {
    window.location.reload();
  };
  // Handle deleting a product
  const deleteProduct = async (productId) => {
    try {
      await axios.delete(base_url + `/products/${productId}`);
      alert(`Product  deleted successfully.`);
      refreshPage();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };
  return (
    <div className="container-fluid position-relative d-flex p-0 products-container">
      <ProductFetcher setProducts={setProducts} setLoading={setLoading} />
      {loading ? <div>Loading...</div> : <p>Welcome Admin</p>}

      <div className="sidebar pe-4 pb-3">{/* Sidebar Navigation */}</div>

      <div className="content">
        <nav className="navbar navbar-expand bg-secondary navbar-dark sticky-top px-4 py-0">
          <button className="btn btn-primary mx-2">
            <Link to="/products" className="text-white">
              View Products
            </Link>
            <br />
          </button>
          <div className="ml-auto products-ribbon">
            <button className="btn btn-primary mx-2">
              <Link to="/add_product" className="text-white">
                Add Product
              </Link>
            </button>
            <button className="btn btn-secondary" onClick={refreshPage}>
              Refresh
            </button>
          </div>
        </nav>

        <table className="table mt-4">
          <thead>
            <tr>
              <th>Product ID</th>
              <th>Name</th>
              <th>Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <React.Fragment key={product.public_id}>
                <tr>
                  <td>{product.public_id}</td>
                  <td productname={product.name}>{product.name}</td>
                  <td>${product.price.toFixed(2)}</td>
                  <td>
                    <button
                      className="btn btn-edit"
                      productId={product.public_id}
                    >
                      <Link
                        to={`/admin/products/${product.public_id}`}
                        // to="/add_product"
                        style={{ color: "white" }}
                        productId={product.public_id}
                      >
                        Edit
                      </Link>
                    </button>
                    <button
                      className="btn btn-delete"
                      onClick={() => deleteProduct(product.public_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Products;
